<template>
  <div class="loginS">
    <div class="loginS_log" style="font-size: 40px; font-weight: bold; color: #c00; padding: 20px;">
      华品富商餐饮盈利系统
      <!-- <img src="@img/logo.png" alt=""> -->
      <!-- <h2>餐添大数</h2> -->
    </div>
    <!-- <img class="referral_img" src="../../assets/img/referral.png" alt=""> -->
    <div class="loginS_box">
      <div class="loginS_left">
        <p>date</p>
        <div class="loginS_box_l">
          <h3>{{tiem_day}}</h3>
          <div>
            <p>Medium</p>
            <p>{{tiem_Medium}}</p>
          </div>
        </div>
      </div>
      <div class="loginS_left">
        <p>time</p>
        <div class="loginS_box_l">
          <h3>{{timer}}</h3>
          <div>
            <p>Medium</p>
            <p>{{timer}}</p>
          </div>
        </div>
      </div>
      <div class="loginS_rigth">
        <div class="enter">
          <p>登陆</p>/
          <p @click="account()">注册新账号</p>/
          <p @click="forget()">忘记密码</p>
        </div>
        <div class="loginS_right_box">
          <div>
            <p>登陆</p>
          </div>
          <div class="login_phone">
            <input type="text" maxlength="11" placeholder="请输入手机号" v-model="phone" onkeyup="value=value.replace(/[^\d]/g,'')" />
          </div>
          <div class="login_password">
            <input :type="mima" placeholder="请输入您的密码" v-model="password" />
            <i @click="eye" class="gai"><img
                :src="suo ? require('../../assets/img/bi.png') : require('../../assets/img/zheng.png')" alt="" /></i>
          </div>
        </div>
        <!-- <el-checkbox class="remember" v-model="checked">记住密码</el-checkbox> -->
        <div>
          <a @click="queding()" :plain="true" class="register">登录</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import config from '@/config'
const phone = process.env.NODE_ENV === 'development' ? config.denglu.phone : ''
const password = process.env.NODE_ENV === 'development' ? config.denglu.mima : ''
import { mapActions} from 'vuex'
  import {
    pwd_login,
    get_my_info,
    get_rel_list,
    insert_m_login_log,
    logout,
  } from '@api/pulic'
  import {query_user_auth} from '@api/foodqiye'

  export default {
    data() {
      return {
        tiem_Medium: '',
        timer: '', //定义一个定时器的变量
        currentTime: new Date(), // 获取当前时间
        tiem_Hours: '',
        checked: false,
        mima: 'password',
        suo: true,
        phone: phone,
        password: password,
        ent_id: '',
        xinyong_cunzai1: false,
        xian: '',
        ming: false,

        QXRouter_list:[
          {
            moudle_id:'1',
            router:'/InfoMsettings'
          },
          {
            moudle_id:'2',
            router:'/FullModule'
          },
          {
            moudle_id:'3',
            router:'/Mubiao'
          },
          {
            moudle_id:'4',
            router:'/MarketingCampaign'
          },
          {
            moudle_id:'5',
            router:'/AnalyseDataSet'
          },
          {
            moudle_id:'6',
            router:'/Centrepot'
          },
          {
            moudle_id:'7',
            router:'/DishManagement'
          },
          {
            moudle_id:'8',
            router:'/DeskManagement'
          },
          {
            moudle_id:'9',
            router:'/PeopleManage'
          },
          {
            moudle_id:'10',
            router:'/workdayShop'
          },
          {
            moudle_id:'11',
            router:'/ApprovalList'
          },
          {
            moudle_id:'12',
            router:'/Public'
          },
          {
            moudle_id:'13',
            router:'/supplier'
          }
        ]
      }
    },
    created: function () {
      var aData = new Date();
      // 获取当前月日
      this.tiem_Medium = aData.getFullYear() + "年" + (aData.getMonth() + 1) + "月" // 获取当前日期
      this.tiem_day = aData.getDate();

      if(aData.getMinutes() <= 9){
        this.timer = aData.getHours() + ':' + 0 + aData.getMinutes()
      } else{
        this.timer = aData.getHours() + ':' + aData.getMinutes()
      }
    },
    methods: {
      ...mapActions(['get_cat_list']),
      eye() {
        this.suo = !this.suo
        if (this.suo) {
          this.mima = 'password'
        } else {
          this.mima = 'text'
        }
      },
      // 确定
      queding() {
        let self = this
        if (this.phone.length !== 0 && this.password.length !== 0) {
          if (/^1[3456789]\d{9}$/.test(this.phone) && this.password.length > 5) {
            // 密码登陆
            pwd_login({
              data: {
                mobile: this.phone,
                pwd: this.password
              }
            }).then(res => {
              if (res.code == 10010) {

                sessionStorage.setItem('user_id', res.body.data.user_id)

                self.$store.state.user_id = res.body.data.user_id;

                self.$store.state.phone_pc = this.phone;
                // sessionStorage.setItem('phone_pc', this.phone)

                this.$message({
                  message: '登录成功',
                  type: 'success'
                })
                get_my_info({data:{user_id:res.body.data.user_id}})
                .then( ress=>{
                  console.log(ress,'------------------');

                  if(ress.code === '10069'){
               
                    sessionStorage.setItem('userInfo',JSON.stringify(ress.body.data))
                    self.$store.state.ent_id = ress.body.data.ent_info.ent_id;
                    sessionStorage.setItem('ent_id',ress.body.data.ent_info.ent_id)

                    self.$store.state.ent_info = ress.body.data.ent_info;
                    sessionStorage.setItem('ent_info', JSON.stringify(ress.body.data.ent_info))


                    
                    return  get_rel_list({data: { user_id: res.body.data.user_id }})
                   }
                 })

                .then( res22 => {
                  console.log(res22,'132131313131313113');
                   if( res22.code === '10091'){
                      sessionStorage.setItem('qiye',JSON.stringify(res22.body.data))

                      // vuex获取菜品二级
                       this.get_cat_list()
                       
                       let qxent_id = res22.body.data.staff_info.ent_id
                       let qxuser_id = res22.body.data.user_id
                       
                       
                      //  this.query_user_auth(qxent_id,qxuser_id) // 获取权限 老夫 知道你想说啥 尽力了/
                      this.$router.push("/EnteroriseNewly");

                       
                   }
                 })


              } else if (res.code == 10011) {
                this.$message({
                  message: '登录失败',
                  type: 'error'
                })
              } else if (res.code == 10012) {
                this.$message({
                  message: '您的密码错误或密码与用户名不匹配',
                  type: 'error'
                })
              } else if (res.code == 10008) {
                this.$message({
                  message: '请先注册',
                  type: 'warning'
                })
              } else if (res.code == 11111) {
                this.$message({
                  message: '您已在别处登陆',
                  type: 'warning'
                })
              }
            })
          } else {
            this.$message({
              message: '您请输入正确',
              type: 'success'
            })
          }
        } else {
          this.$message({
            message: '您请完善账号密码',
            type: 'success'
          })
        }
      },
      account() {
        this.$router.push('/registerS')
      },
      forget() {
        this.$router.push('PasswordS')
      },


      query_user_auth(ent_id,user_id){
        let data = {
          ent_id:ent_id,
          user_id:user_id
        }
        console.log('===',data);
        query_user_auth({
            data
        }).then(res => {
          if (res.code == 200) {
            console.log('qqqqqqqqq', data, res);
            let jurisdiction = res.body.data
            this.$store.state.jurisdiction = jurisdiction;
            sessionStorage.setItem('jurisdiction',JSON.stringify(jurisdiction))
            if (jurisdiction.flag_boss=='0') {
              if (jurisdiction.flag_main_mng=='0') {
                if (jurisdiction.falg_sub_mng=='0') {
                  // 
                  this.$router.push("/EnteroriseNewly");
                }else{
                  // 是子管理员
                  console.log('是子管理员是子管理员是子管理员');

                  if (jurisdiction.web_sub_mng_list.length == 0) {
                    this.$router.push("/AdminAccount") // AdminAccount
                  }else{
                    let router = jurisdiction.web_sub_mng_list[0].router
                    this.$router.push(`/${router}`) 
                  }
                }
              }else{
                //是主管理员
                console.log('是主管理员');
              this.$router.push("/EnteroriseNewly");
              }
            }else{
              // 老板
              console.log('是老板老板老板理员');
              this.$router.push("/EnteroriseNewly");
            }
          }
        })
      }

    },
    
    computed:{
      
    }
  }

</script>
<style scoped lang="scss">
  .loginS {
    position: relative;
    background-image: url(../../assets/img/toRegister.png);
    background-size: 100% 100%;
    height: 100vh;
    width: 100vw;
    background-repeat: no-repeat;

    .referral_img {
      position: absolute;
      left: 5%;
      top: 46%;
      transform: translate(0, -50%);
    }

    .loginS_log {
      h2 {
        font-size: 34px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
      }

      img {
        margin: 40px 0 0 40px;
      }
    }

    .loginS_box {
      width: 80%;
      height: 200px;
      position: fixed;
      left: 50%;
      transform: translate(-50%);
      bottom: 40px;
      background: #2D4662;
      border-radius: 24px;
      display: flex;
      padding: 30px 30px;
      box-sizing: border-box;
      justify-content: space-between;

      .loginS_left {
        &>p {
          font-size: 22px;
          font-weight: 500;
          color: #75A4D9;
          margin-bottom: 26px;
        }

        .loginS_box_l {
          display: flex;

          h3 {
            font-size: 70px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            margin-right: 18px;
          }

          div {
            width: 150px;
            margin-top: 10px;

            p {
              font-size: 22px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #75A4D9;
              margin-bottom: 10px;
            }
          }
        }
      }

      .loginS_rigth {
        .enter {
          display: flex;
          font-size: 20px;
          justify-content: center;
          color: #fff;
          margin-bottom: 30px;

          p {
            color: #FFFFFF;
            font-size: 20px;
            margin: 0 6px;

            &:first-of-type {
              margin: 0 6px 0 0;
              color: #4AF0FF;
            }

            &:hover {
              cursor: pointer;
            }
          }
        }

        .loginS_right_box {
          display: flex;

          div {
            margin-right: 20px;

            p {
              color: #FFFFFF;
              font-size: 16px;
              margin-top: 10px;
            }
          }

          input {
            width: 182px;
            height: 36px;
            background: #D2DFE8;
            border: 0;
            border-radius: 18px;
            padding-left: 10px;
            font-size: 16px;
            font-weight: 600;
            color: #51758D;

            &::placeholder {
              color: #51758D;
            }

            &:focus {
              outline: 0
            }
          }
        }

        .login_password {
          position: relative;

          .gai {
            position: absolute;
            right: -32px;
            top: -8px;

            img {
              margin: 10px 0 0 0;
            }
          }
        }

        .register {
          display: block;
          margin: 20px auto 0 auto;
          color: #fff;
          font-size: 20px;
          text-align: center;
          line-height: 40px;
          width: 160px;
          height: 40px;
          background: #93bddd;
          border-radius: 30px;
        }
      }
    }
  }

</style>
